import React from 'react';
import Navigation from './screens/navigation';
import NamePicture from './screens/profile';
import { styles } from './styles';
import meImage from './me.png';

function App() {
  return (
    <div style={styles.app}>
      <Navigation />
      <NamePicture 
        name="Nolan Johnson" 
        imageUrl={meImage}
      />
      {}
    </div>
  );
}

export default App;