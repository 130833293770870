import React from 'react';
import { styles } from '../styles';

const NamePicture = ({ name, imageUrl }) => {
  return (
    <div style={styles.namePictureContainer}>
      <h1 style={styles.name}>{name}</h1>
      <img src={imageUrl} alt={name} style={styles.picture} />
    </div>
  );
};

export default NamePicture;