// src/styles.js
export const styles = {
    app: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
    },
    navigation: {
      backgroundColor: '#f8f8f8',
      padding: '10px 0',
    },
    navList: {
      listStyle: 'none',
      display: 'flex',
      justifyContent: 'center',
      padding: 0,
    },
    navItem: {
      margin: '0 15px',
    },
    navLink: {
      textDecoration: 'none',
      color: '#333',
      fontWeight: 'bold',
    },
    namePictureContainer: {
      textAlign: 'center',
      marginTop: '50px',
    },
    name: {
      fontSize: '2.5em',
      color: '#333',
    },
    picture: {
      width: '200px',
      height: '200px',
      borderRadius: '50%',
      objectFit: 'cover',
      marginTop: '20px',
    },
  };