import React from 'react';
import { styles } from '../styles';

const Navigation = () => {
  return (
    <nav style={styles.navigation}>
      <ul style={styles.navList}>
        <li style={styles.navItem}><a href="#home" style={styles.navLink}>Home</a></li>
        <li style={styles.navItem}><a href="#about" style={styles.navLink}>About</a></li>
        <li style={styles.navItem}><a href="#projects" style={styles.navLink}>Projects</a></li>
        <li style={styles.navItem}><a href="#contact" style={styles.navLink}>Contact</a></li>
      </ul>
    </nav>
  );
};

export default Navigation;